export default {
  title: "اصناف",
  entityName: "type",
  properties: [
    {
      text: "#UID",
      value: "id",
      align: "right",
      field: "_id",
      sortable: false,
    },
    {
      text: "نوع المقالة",
      value: "type",
      align: "right",
      field: "_type",
      sortable: false,
    },
  ],
  getHeader() {
    let newArr = [];

    this.properties.forEach(({ text, value, align, sortable }) => {
      newArr.push({ text, value, align, sortable });
    });

    return newArr;
  },
  getSortDropdown() {
    let newArr = [];

    this.properties.forEach(({ text, field }) => {
      newArr.push({ text, field });
    });

    return newArr;
  },
};

<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-3"
        depressed
        v-show="!loading && selected.length > 1"
        outlined
        large
        :loading="loading"
        @click="onClick"
      >
        <v-icon>
          mdi-merge
        </v-icon>
        <span class="mr-1" style="font-size: 1.2rem">
          دمج
        </span>
      </v-btn>
    </template>
    <div v-if="loading">
      جاري تحميل....
    </div>
    <v-card v-else>
      <v-card-title>
        <span class="text-h4">حدد الخيار الذي تريد الاحتفاظ به</span>
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="mainId">
          <v-radio
            v-for="n in selectedWithCount"
            :key="n.id"
            :label="getText(n)"
            :value="n.id"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-4" @click="closeModal" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            إلغاء
          </span>
          <v-icon>cancel</v-icon>
        </v-btn>
        <v-btn class="mr-4" @click="performMerge" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            دمج
          </span>
          <v-icon>mdi-merge</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
import { getArticleCount } from "@/services/Authors.api";
export default {
  props: ["loading", "selected", "descriptiveName", "withCount"],
  data: () => ({
    dialog: false,
    mainId: 0,
    selectedWithCount: [],
  }),
  methods: {
    ...mapMutations("notification", { notifyVuex: "notify" }),
    closeModal() {
      this.selectedWithCount= [];
      this.dialog = false;
      if (this.withCount) {
        this.$emit("clear")
      }
    },
    onClick() {
      if (this.withCount) {
        this.setLoading(true);
        const promises = [];
        this.selected.forEach((el) => {
          promises.push(getArticleCount(el.id));
        });
        Promise.all(promises).then((values) => {
          const arr = [];
          values.forEach((res, idx) => {
            arr[idx] = { ...this.selected[idx], count: res.data.payload };
          });
          this.selectedWithCount = arr;
          this.setLoading(false);
        });
      } else {
        this.selectedWithCount = this.selected;
      }
    },
    notify(message) {
      this.notifyVuex({ show: true, message });
    },
    setLoading(status) {
      this.$emit("set-loading", status);
    },
    performMerge() {
      if (this.mainId) {
        this.$emit("merge", {
          mainId: this.mainId,
          otherIds: this.getNonMainId(),
          loadingFunction: this.setLoading,
          inCaseOfError: this.notify,
        });
        this.closeModal();
      }
    },
    getText(el) {
      let partOne = `الخيار ${el.id} : `;
      let partTwo = `${el[this.descriptiveName]}`;
      let partThree = el.count ? ` - ${el.count} مقالات` : "";
      return partOne + partTwo + partThree;
    },
    getNonMainId() {
      const ids = [];
      this.selected.forEach((el) => {
        if (!(el.id === this.mainId)) {
          ids.push(el.id);
        }
      });
      return ids;
    },
  },
};
</script>

<style></style>
